<template>
  <v-dialog v-model="showFeeDialog" persistent max-width="470">
    <v-card>
      <v-card-title>Fee Details:</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-title>Subtotal</v-list-item-title>
            <v-list-item-action>{{
              all_fees.sub_total | currency
            }}</v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Tips</v-list-item-title>
            <v-list-item-action v-if="is_chef">{{
              all_fees.producer_tip | currency
            }}</v-list-item-action>
            <v-list-item-action v-else>{{
              all_fees.tip | currency
            }}</v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>{{ $t("Common.tax") }}</v-list-item-title>
            <v-list-item-action>{{
              all_fees.tax | currency
            }}</v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>{{
              $t("Common.service_fee")
            }}</v-list-item-title>
            <v-list-item-action>{{
              all_fees.service_fee | currency
            }}</v-list-item-action>
          </v-list-item>
          <v-list-item v-if="all_fees.discount > 0">
            <v-list-item-title>Discount <span v-if="all_fees.discount_producer === 'TAKEIN'">(by TAKEIN)</span></v-list-item-title>
            <v-list-item-action class="red--text"
              >-{{ all_fees.discount | currency }}</v-list-item-action
            >
          </v-list-item>
          <v-list-item v-if="all_fees.delivery_discount > 0">
            <v-list-item-title>Delivery Discount <span v-if="all_fees.discount_producer === 'TAKEIN'">(by TAKEIN)</span></v-list-item-title>
            <v-list-item-action class="red--text"
              >-{{ all_fees.delivery_discount | currency }}</v-list-item-action
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title>{{ $t("Common.total") }}</v-list-item-title>
            <v-list-item-action v-if="is_chef">{{
              all_fees.producer_total | currency
            }}</v-list-item-action>
            <v-list-item-action v-else>{{
              all_fees.total | currency
            }}</v-list-item-action>
          </v-list-item>
        </v-list>
        <div class="pa-2 grey lighten-2" v-if="!is_chef">
          <h4>{{ $t("Common.authorization_title") }}</h4>
          <p>{{ $t("Common.authorization_sub") }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          depressed
          color="primary"
          @click.stop="showFeeDialog = false"
          >Ok</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: ["all_fees", "is_chef"],
    data() {
      return {
        showFeeDialog: false
      };
    },
    methods: {
      openDialog() {
        this.showFeeDialog = true;
      }
    }
  };
</script>
